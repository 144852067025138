.chevron {
  width: 8px;
  height: 8px;
  border-right: 2px solid #6b7280;
  border-bottom: 2px solid #6b7280;
  display: inline-block;
  transition: transform 0.2s ease;
}

.chevronRight {
  transform: rotate(-45deg);
}

.chevronDown {
  transform: rotate(45deg);
}

.tableContainer {
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  table-layout: fixed;
}

.table th {
  margin: 0;
  height: 48px;
  padding: 0 24px;
  border-bottom: 1px solid #e5e7eb;
  vertical-align: middle;
  text-align: start;
  position: relative;
}

.table td {
  margin: 0;
  height: 48px;
  padding: 0 24px;
  border-bottom: 1px solid #e5e7eb;
  vertical-align: middle;
  text-align: start;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  background-color: #e4e4e4;
  color: #4b5563;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.05em;
}

.table td {
  font-size: 11.5px;
  color: #374151;
}

.table th:last-child,
.table td:last-child {
  width: 48px;
  padding: 0 20px;
}

.table tbody tr {
  transition: background-color 0.2s;
}

.table tbody tr:hover {
  background-color: #f9fafb;
}

.expandButton {
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.expandButton:hover {
  background-color: #f3f4f6;
}

.expandButton svg {
  width: 16px;
  height: 16px;
  color: #6b7280;
}

.expandedContent {
  background-color: #ffffff;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}

.expandedContent h4 {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  margin: 0 0 16px 0;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-left: 70px;
  margin-top: 10px;
}

.detailItem label {
  display: block;
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 4px;
}

.detailItem p {
  margin: 0;
  color: #111827;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.skill-tag {
  display: inline-block;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  color: #1e40af;
  background-color: #dbeafe;
  border-radius: 9999px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: white;
  border-top: 1px solid #e5e7eb;
}

.paginationInfo {
  align-content: center;
  font-size: 12px;
  font-weight: 900;
  color: #6b7280;
}

.paginationControls {
  display: flex;
  align-items: center;
  gap: 16px;
}

.paginationButtons {
  display: flex;
  gap: 0px;
}

.paginationButton {
  font-size: 10px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  margin: 0px;
  padding: 0 8px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  color: #1677ff;
  cursor: pointer;
  
  &:hover:not(:disabled) {
    border-color: #1677ff;
    color: #1677ff;
  }
  
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
}

.paginationButtonActive {
  background-color: #1677ff;
  border-color: #1677ff;
  color: #fff;
  
  &:hover {
    background-color: #1677ff;
    border-color: #1677ff;
    color: #fff;
  }
}

.paginationEllipsis {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  margin: 0 2px;
  color: rgba(0, 0, 0, 0.45);
}

.paginationButton:hover:not(:disabled) {
  background-color: #f9fafb;
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.paginationText {
  font-size: 14px;
  color: #374151;
}

.subsectionTitle {
  font-size: 12px;
  font-weight: 400;
  color: #111827;
  margin: 0 0 16px 0;
  background-color: #a6efff;
  padding: 10px;
}

.sectionHeader {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  background-color: #d8d8d8;
  padding: 10px;
}

.sectionTable {
  width: 100% !important;
}

.cellField {
  text-align: start;
}

.cellValue {
  text-align: end !important;
}

.updateTable {
  justify-self: stretch !important;
}
